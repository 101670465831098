import React from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';

export const StyledBurger = styled.button`
  /* position: absolute;
  right: 30px;
  top: 23px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  /* ${({ theme }) => theme.mqTablet} {
    display: none;
  } */

  @media only screen and (min-width: 640px) {
    display: none;
  }

  span {
    width: 30px;
    height: 2px;
    background: #5dd882;
    border-radius: 10px;
    transition: all 150ms ease-in;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isOpen }) => (isOpen ? 'translateX(20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const Burger = ({ isOpen, setIsOpen, ...props }) => {
  const isExpanded = !!isOpen;
  return (
    <StyledBurger aria-label="Toggle menu" aria-expanded={isExpanded} isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} {...props}>
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};

Burger.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
};

export default Burger;
