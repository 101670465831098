exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("./../../../src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-skup-medali-orderow-odznaczen-jsx": () => import("./../../../src/pages/skup-medali-orderow-odznaczen.jsx" /* webpackChunkName: "component---src-pages-skup-medali-orderow-odznaczen-jsx" */),
  "component---src-pages-skup-monet-i-banknotow-jsx": () => import("./../../../src/pages/skup-monet-i-banknotow.jsx" /* webpackChunkName: "component---src-pages-skup-monet-i-banknotow-jsx" */),
  "component---src-pages-skup-monet-ii-rp-jsx": () => import("./../../../src/pages/skup-monet-ii-rp.jsx" /* webpackChunkName: "component---src-pages-skup-monet-ii-rp-jsx" */),
  "component---src-pages-skup-monet-olsztyn-jsx": () => import("./../../../src/pages/skup-monet-olsztyn.jsx" /* webpackChunkName: "component---src-pages-skup-monet-olsztyn-jsx" */),
  "component---src-pages-skup-starych-banknotow-jsx": () => import("./../../../src/pages/skup-starych-banknotow.jsx" /* webpackChunkName: "component---src-pages-skup-starych-banknotow-jsx" */),
  "component---src-pages-stare-pocztowki-jsx": () => import("./../../../src/pages/stare-pocztowki.jsx" /* webpackChunkName: "component---src-pages-stare-pocztowki-jsx" */),
  "component---src-pages-wycena-monet-i-banknotow-jsx": () => import("./../../../src/pages/wycena-monet-i-banknotow.jsx" /* webpackChunkName: "component---src-pages-wycena-monet-i-banknotow-jsx" */)
}

