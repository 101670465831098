/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Footer = styled.footer`
  color: #fff;
  font-size: 1.6rem;
  height: 85px;
  padding: 20px 0 15px;

  .footer__info {
    border-top: 1px solid rgba(199,87,132,.25);
    margin-top: 75px;
    padding-top: 50px;
    padding-bottom: 50px;

    ul {
      list-style: none;
      padding: 0;
    }
  }

  .grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);

    .subtitle {
      font-size: 2rem;
      margin: 0 0 16px;

      @media only screen and (min-width: 640px) {
        margin: 0 0 24px;
      }
    }

    address {
      font-style: normal;
    }

    &__menu {
      /* display: none; */

      .sep {
        margin-top: 17px;
        padding-top: 10px;
        position: relative;

        &::before {
          content: '';
          border-top: 1px solid rgba(199,87,132,.25);
          position: absolute;
          top: 0;
          width: 35px;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      /* grid-template-columns: repeat(3, 1fr); */
      grid-template-columns: 35% 25% 15% 15%;

      &__menu {
        display: block;
      }
    }

    a {
      text-decoration: none;
      
      &:hover,
      &:focus {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    svg {
      margin-right: 10px;
      vertical-align: middle;

      &.phone {
        margin-left: 2px;
        position: relative;
        top: -1px;
      }

      path {
        transition: fill 200ms ease-in;
        fill: #5dd882;
      }
    }
  }

  dl {
    display: flex;
    flex-flow: row wrap;
    margin: 5px 0 0;
    max-width: 320px;
  }

  dt {
    flex-basis: 60%;
  }

  dd {
    flex-basis: 40%;
    flex-grow: 1;
    margin: 0;
  }

  .copyright {
  }
`;

const Copyright = styled.div`
  border-top: 1px solid rgba(199,87,132,.25);
  font-size: 1.4rem;
  padding-top: 14px;
  padding-bottom: 15px;
  position: relative;

  p {
    margin: 0;
    text-align: center;
  }

  svg {
    margin-left: 5px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }

  @media only screen and (min-width: 400px) {
    display: flex;
    flex-wrap: wrap;

    > p {
      text-align: left;
      width: 50%;

      &:nth-child(2) {
        text-align: right;
      }
    }
  }
`;

const FooterCopyright = styled.p`
  text-align: left;

  a {
    color: #fff;
    margin-left: 5px;
    text-decoration: none;

    &:hover,
    &:focus {
      color: #5dd882;
    }
  }
`;

const FooterMade = styled.p`
  text-align: right;
`;

// last update
const date = new Date();
const time = date.toLocaleTimeString('pl-PL', {
  hour: 'numeric',
  minute: 'numeric',
});

const result = date.toLocaleDateString('pl-PL', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

const lastUpdate = `Ostatnia aktualizacja: ${`${result}, godz: ${time}`}`;

const encodeEmail = (email) => {
  let encodedEmail = '';
  for (let i = 0; i < email.length; i++) {
    encodedEmail += `&#${email.charCodeAt(i)};`;
  }
  return encodedEmail;
};

// Adres e-mail do zakodowania
const emailAddress = 'kontakt@monety.olsztyn.pl';

// Zakoduj adres e-mail
const encodedEmailAddress = encodeEmail(emailAddress);

export const FooterComponent = () => (
  <Footer>
    <div className="row footer__info">

      <div className="grid">
        <div className="grid__address">
          <address itemScope itemType="https://schema.org/LocalBusiness">
            <p className="subtitle">
              <strong itemProp="name">Monety Olsztyn</strong>
            </p>
            <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress" style={{ marginBottom: '20px', display: 'none' }}>
              <span itemProp="streetAddress">ul. Mickiewicza</span>,&nbsp;
              <span itemProp="postalCode">10-511</span>,&nbsp;
              <span itemProp="addressLocality">Olsztyn, Polska</span>
            </div>
            <p style={{ margin: '0 0 10px' }}>
              <a href="tel:507506476" itemProp="telephone">
                <svg width="15" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mobile-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="phone">
                  <path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"></path>
                </svg>
                507 506 476
              </a>
            </p>
            <p style={{ marginBottom: '35px' }}>
              <a href="mailto:kontakt@monety.olsztyn.pl" itemProp="email">
                <svg width="19" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>
                <span dangerouslySetInnerHTML={{ __html: encodedEmailAddress }}></span>
              </a>
            </p>

            <p style={{ margin: '0' }}>
              <strong>Godziny otwarcia</strong>
              :
            </p>

            <dl>
              <dt>Poniedziałek – Piątek</dt>
              <dd>
                <meta itemProp="openingHours" content="Mo-Fr 10:00-18:00" />
                10:00–18:00
              </dd>

              <dt>Sobota</dt>
              <dd>
                <meta itemProp="openingHours" content="Sa 10:00-15:00" />
                10:00–15:00
              </dd>

              <dt>Niedziela</dt>
              <dd>
                <meta itemProp="openingHours" content="close" />
                nieczynne
              </dd>
            </dl>
          </address>
        </div>

        <div className="grid__services">
          <p className="subtitle">Usługi</p>
          <ul>
            <li>
              <Link to="/skup-monet-i-banknotow/">Skup numizmatów</Link>
            </li>

            <li>
              <Link to="/skup-starych-banknotow/">Skup banknotów</Link>
            </li>

            <li>
              <Link to="/skup-monet-olsztyn/">Skup monet</Link>
            </li>

            <li>
              <Link to="/skup-medali-orderow-odznaczen/">Skup medali, orderów, odznaczeń</Link>
            </li>

            <li>
              <Link to="/stare-pocztowki/">Skup starych pocztówek</Link>
            </li>
          </ul>
        </div>

        <div className="grid__menu">
          <p className="subtitle">Inne</p>
          <ul>
            <li>
              <Link to="/faq/">FAQ</Link>
            </li>

            <li>
              <Link to="/kontakt/">Kontakt</Link>
            </li>

            <li className="sep">
              <Link to="/cookies/">Cookies</Link>
            </li>

            <li>
              <Link to="/polityka-prywatnosci/">Polityka prywatności</Link>
            </li>
          </ul>
        </div>

        <div>
          <p className="subtitle">Obsewuj nas</p>
          <ul>
            <li>
              <a href="https://www.facebook.com/skup.monet.olsztyn" rel="nofollow" title="Skup monet i banknotów Olsztyn">Facebook</a>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <Copyright className="row">
      <FooterCopyright>
        © <Tippy content={lastUpdate}><span>{new Date().getFullYear()}</span></Tippy>
        <Link to="/" style={{ marginRight: '7px' }}>Monety Olsztyn</Link>
      </FooterCopyright>
      <FooterMade>
        Made with
        <svg width="16" height="14" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" title="Love">
          <path d="M1.26215 1.11782C3.7747 -0.932692 6.99913 1.36587 8.29727 2.77147C12.6524 -2.89226 17.1331 1.26104 17.1331 5.12792C17.1331 8.47654 14.8299 11.9078 8.29728 16.0006C1.55528 12.528 -1.87853 3.68097 1.26215 1.11782Z" fill="#F087C8" />
        </svg>
      </FooterMade>
    </Copyright>

  </Footer>
);

export default FooterComponent;
